<!--
  @name: 押金变更记录
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <div class="wrapper flex-col flex-between ">
    <panel-head title="押金变更记录"></panel-head>

    <section class="main mt-2x">
      <header class="container">
        <!--头部查询-->
        <a-form-model layout="inline" :model="ruleForm">
          <a-form-model-item label="押金收取时间">
            <a-range-picker
              :allow-clear="false"
              class="input"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="押金变更类型" prop="status">
            <a-select
              class="input"
              v-model="ruleForm.changeType"
              placeholder="请选择"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option :value="1">收取押金</a-select-option>
              <a-select-option :value="2">押金支付</a-select-option>
              <a-select-option :value="2">退还支付</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="操作人">
            <a-input
              class="input"
              v-model="ruleForm.operator"
              placeholder="请输入操作人"
              allow-clear
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="search">查询</a-button>
            <a-button class="mh-2x" @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </header>
      <!-- 表格 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <template slot="changeTime" slot-scope="{ changeTime }">
          {{ changeTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template slot="memberName" slot-scope="{ memberName, memberPhone }">
          {{ memberName }}/{{ memberPhone }}
        </template>
        <template slot="changeAmount" slot-scope="{ changeAmount }">
          {{ changeAmount | money }}
        </template>
        <template slot="remainLeaseAmount" slot-scope="{ remainLeaseAmount }">
          {{ remainLeaseAmount | money }}
        </template>
        <template slot="action" slot-scope="{}">
          <a-button class="ml-2x" type="link p-0" @click="visible = true">
            归还结算
          </a-button>
        </template>
      </a-table>
      <!--底部-->
      <footer class="flex-end p-2x">
        <a-pagination
          v-model="ruleForm.page"
          :default-page-size="ruleForm.size"
          :total="total"
          :page-size-options="['5', '10', '20', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
      </footer>
    </section>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import moment from "moment";
import { orderProductLeaseDepostitChangeRecord } from "../../../api/order";

export default {
  name: "change-list",
  components: { PanelHead },
  data() {
    return {
      dateRange: [moment(), moment()],
      ruleForm: {
        page: 1,
        size: 5,
        changeStartTime: moment()
          .startOf("date")
          .valueOf(), // 开始时间
        changeEndTime: moment()
          .endOf("date")
          .valueOf(), // 结束时间
        changeType: "", // 押金变更类型
        operator: "" // 操作人
      },
      data: [],
      total: 0,
      columns: [
        {
          title: "商户名称",
          dataIndex: "merchantName",
          width: 130
        },
        {
          title: "变更时间",
          scopedSlots: { customRender: "changeTime" },
          width: 130
        },
        {
          title: "会员",
          scopedSlots: { customRender: "memberName" },
          width: 130
        },
        {
          title: "票号",
          dataIndex: "leaseCode",
          width: 130
        },
        {
          title: "押金变更类型",
          dataIndex: "changeTypeText",
          width: 130
        },
        {
          title: "变更押金金额",
          scopedSlots: { customRender: "changeAmount" },
          width: 120
        },
        {
          title: "剩余押金金额",
          scopedSlots: { customRender: "remainLeaseAmount" },
          width: 120
        },
        {
          title: "操作人",
          dataIndex: "operator",
          width: 180
        },
        {
          title: "押金收取备注",
          dataIndex: "remarks",
          width: 180
        }
      ]
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      orderProductLeaseDepostitChangeRecord({
        orderId: this.$route.query.id,
        ...this.ruleForm
      })
        .then(({ records, total }) => {
          this.data = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.ruleForm.changeStartTime = moment(start)
        .startOf("date")
        .valueOf();
      this.ruleForm.changeEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 重置
    reset() {
      this.dateRange = [moment(), moment()];
      this.ruleForm.changeStartTime = moment()
        .startOf("date")
        .valueOf(); // 开始时间
      this.ruleForm.changeEndTime = moment()
        .endOf("date")
        .valueOf(); // 开始时间
      this.ruleForm.changeType = "";
      this.ruleForm.operator = "";
    },
    // 条数改变
    sizeChange(current, size) {
      this.ruleForm.page = 1;
      this.ruleForm.size = size;
      this.loadData();
    },
    // 查询
    search() {
      this.ruleForm.page = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 230px;
  margin-right: 16px;
}
</style>
