<!--
  @name: 底部会员按钮
  @author: fengyanlong
  @date:2021-09-24
  @event:{
      click:{
          type:Function,
          default:null
      }
  }
-->
<template>
  <div class="relative member-menu" @click="handleClick">
    <a-button
      v-permission="vip"
      size="large"
      type="primary"
      shape="round"
      :ghost="!memberInfo.id"
      class="res"
    >
      <span v-if="memberInfo.id && memberInfo.whetherTemporaryMember === 1"
        >临时会员</span
      >
      <span v-else-if="memberInfo.id"
        >{{ memberInfo.mobile }} {{ memberInfo.name }}</span
      >
      <span v-else>会员</span>
      <span class="f-key ml-1x" v-if="shortcutKey">(F1)</span>
    </a-button>
    <i
      class="iconfont iconicon--shanchu red"
      v-if="memberInfo.id"
      @click.stop="clearMember"
    ></i>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "member-menu",
  props: {
    // 传入 会员显示 权限
    vip: {
      type: String
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({ shortcutKey: "setting/getshortcutKey" }),
    ...mapGetters("common", ["memberInfo"])
  },
  created() {},
  methods: {
    //清理会员信息
    clearMember() {
      //清除会员信息
      this.$store.commit("common/UpdateMember", {});
    },
    handleClick() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";
.member-menu {
  width: 13%;
}
.iconfont {
  font-size: 22px;
  position: absolute;
  top: -15px;
  right: -13px;
}
</style>
