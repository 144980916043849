<!--
  @name: 租赁首页
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <!--三级路由-->
  <router-view />
</template>

<script>
export default {
  name: "lease-index",
  data() {
    return {};
  },
  created() {},

  methods: {}
};
</script>

<style lang="less" scoped></style>
