<!--
  @name: 退还押金页面
  @author: fengyanlong
  @date:2021-08-19
-->
<template>
  <div class="wrapper flex-col flex-start">
    <panel-head title="退还押金"></panel-head>
    <!--main-->
    <section class="main mt-2x">
      <header class="container">
        <a-form-model layout="inline" :model="ruleForm">
          <a-form-model-item label="会员号码">
            <a-input
              class="input"
              v-model="ruleForm.memberPhone"
              placeholder="请扫码查询/输入会员手机号查询"
              @pressEnter="search"
              allow-clear
            >
              <a-icon type="scan" slot="suffix" class="font-l primary" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="票号">
            <a-input
              class="input"
              v-model="ruleForm.auxiliaryId"
              placeholder="请扫码查询/输入票码"
              allow-clear
              @pressEnter="search"
            >
              <a-icon type="scan" slot="suffix" class="font-l primary" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="search">查询</a-button>
          </a-form-model-item>
        </a-form-model>
      </header>

      <template v-if="loaded">
        <!--表格-->
        <deposit-list
          :table-data="data"
          class="flex-grow"
          @reload="loadData"
        ></deposit-list>
        <!--底部-->
        <footer class="flex-end p-2x">
          <a-pagination
            v-model="ruleForm.page"
            :default-page-size="ruleForm.size"
            :total="total"
            :page-size-options="['10', '20', '50', '100']"
            show-size-changer
            @change="loadData"
            @showSizeChange="sizeChange"
          />
          <!--<a-button type="primary" ghost @click="$router.back()">返回</a-button>-->
        </footer>
      </template>

      <p class="center disabled font-xxxl" v-if="!loaded">
        请输入会员号码/票号查询
      </p>
    </section>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead";
import moment from "moment";
import { orderDeposittakerecord } from "../../../api/search";
import DepositList from "./component/DepositList";

export default {
  name: "deposit-return",
  components: { PanelHead, DepositList },
  data() {
    return {
      ruleForm: {
        page: 1,
        size: 10,
        auxiliaryId: "", // 票号
        memberPhone: "" // 会员手机号
      },
      visibleSearch: false,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      data: [],
      total: 0,
      loaded: false
    };
  },
  created() {},
  methods: {
    loadData() {
      if (this.ruleForm.auxiliaryId || this.ruleForm.memberPhone) {
        orderDeposittakerecord(this.ruleForm)
          .then(({ records, total }) => {
            this.data = records;
            this.total = total;
            this.loaded = true;
          })
          .catch(() => {});
      } else {
        this.$message.warning("请输入会员号码/票号");
      }
    },
    // 日期选择
    dateChange([start, end]) {
      this.ruleForm.chargeBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.ruleForm.chargeEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 条数改变
    sizeChange(current, size) {
      this.ruleForm.page = 1;
      this.ruleForm.size = size;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.ruleForm.auxiliaryId = "";
      this.ruleForm.memberPhone = "";
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 230px;
  margin-right: 16px;
}
</style>
