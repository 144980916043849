<!--
  @name: 租赁物品列表
  @author: heyan
  @date:2021-08-19
-->

<template>
  <section class="flex-start flex-grow">
    <!--租赁列表-->
    <div
      class="wrapper flex-between flex-col relative wrapper-left"
      v-show="!showMember"
    >
      <!-- 头部搜索 -->
      <header class="header">
        <a-form layout="inline">
          <a-form-item label="物品名称">
            <a-input-search
              placeholder="请输入物品名称"
              @search="onSearch"
              v-inputfocus
              enter-button
              allowClear
            />
          </a-form-item>
        </a-form>
      </header>
      <!-- 列表开始 -->
      <a-empty description="暂无数据" v-if="goodsList.length === 0" />
      <div class="main flex-grow mt-2x container">
        <a-row :gutter="[16, 16]">
          <a-col
            :xxl="8"
            :xl="12"
            :lg="24"
            v-for="item in goodsList"
            :key="item.id"
          >
            <div
              class="ticket-card flex-between flex-col flex-grow"
              @click="addGoods(item)"
            >
              <p class="font-xl text-1 text" v-html="item.name"></p>
              <p>
                <span v-if="item.deductionType === 1"
                  >按次收费：{{ item.sellingPrice | money }} / 次
                </span>
                <!-- 按时收费，文字长度大于15时，用文字提示框 -->
                <a-tooltip
                  v-if="item.deductionType === 2 && item.leassRule.length > 15"
                  placement="topLeft"
                  :title="item.leassRule | money"
                >
                  <span
                    >按时收费<a-icon type="question-circle" class="ml-2x"
                  /></span>
                </a-tooltip>
                <!-- 按时收费，文字长度小于15时，直接显示-->
                <span
                  v-if="item.deductionType === 2 && item.leassRule.length < 15"
                  class="text-2"
                  >按时收费: {{ item.leassRule | money }}
                </span>
              </p>
              <p>物品价值：{{ item.productValue | money }}</p>
              <p v-if="item.deductionType === 2">
                封顶金额：{{ item.maxAmount | money }}
              </p>
              <div>剩余库存：{{ item.stock }}</div>
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- 列表结束 -->
      <!-- 底部开始 -->
      <footer class="footer">
        <member-menu @click="showMember = true"></member-menu>
        <div
          v-permission="'appLeaseIndexDepositcollection'"
          class="menu"
          @click="$router.push({ path: '/lease/deposit/collection' })"
        >
          收取押金<span class="f-key" v-if="shortcutKey">(F2)</span>
        </div>
        <div
          v-permission="'appLeaseIndexreturnofdownpayment'"
          class="menu"
          @click="$router.push({ path: '/lease/deposit/return' })"
        >
          退还押金<span class="f-key" v-if="shortcutKey">(F3)</span>
        </div>
        <div
          v-permission="'appLeaseIndexItemreturn'"
          class="menu"
          @click="$router.push({ path: '/lease/goods/return' })"
        >
          物品归还<span class="f-key" v-if="shortcutKey">(F4)</span>
        </div>
        <div
          v-permission="'appLeaseIndexDepositcollectionrecord'"
          class="menu"
          @click="$router.push({ path: '/lease/deposit/record' })"
        >
          押金收取记录<span class="f-key" v-if="shortcutKey">(F5)</span>
        </div>
        <div
          v-permission="'appLeaseIndexLeaserecords'"
          class="menu"
          @click="$router.push({ path: '/lease/record' })"
        >
          租赁记录<span class="f-key" v-if="shortcutKey">(F6)</span>
        </div>
      </footer>
      <!-- 底部结束 -->
    </div>
    <!-- 会员详情 -->
    <member-detail
      class="flex-grow"
      v-show="showMember"
      format="lease"
      @close="showMember = false"
    ></member-detail>

    <!-- 购物车开始 -->
    <div class="wrapper shopping-cart">
      <header class="header">租赁清单</header>
      <section
        class="main flex-grow"
        :class="[
          { 'flex-col': cartList.length <= 0 },
          { 'flex-center': cartList.length <= 0 }
        ]"
      >
        <!-- 空购物车 -->
        <a-empty
          :image="emptyCar"
          :description="false"
          :image-style="{
            width: '238px',
            height: '238px',
            margin: '0 auto 50px'
          }"
          v-if="cartList.length <= 0"
        />
        <!-- 购物车列表 -->
        <template v-if="cartList">
          <div
            class="container"
            v-for="(product, index) in cartList"
            :key="product.id"
          >
            <p class="text-2">{{ product.name }}</p>
            <div class="flex-between mt-2x">
              <div class="quantity-style">
                <div class="minus-style" @click="reduceNum(index, product)">
                  <a-icon type="minus" />
                </div>
                <a-input
                  v-model="product.buyNum"
                  size="small"
                  class="num-style"
                  @keydown="onkeydown($event)"
                  @focus="
                    $store.commit(
                      'system/setSYSTEM_INPUT_IS_DEFAULT_DOWN',
                      true
                    )
                  "
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                  @blur="
                    () => {
                      handleChange(product);
                    }
                  "
                ></a-input>
                <div
                  class="plus-style"
                  :class="{ disabled: isAddNumDisabled(product) }"
                  @click="addNum(product)"
                >
                  <a-icon type="plus" />
                </div>
              </div>
              <a-button type="link" @click="delGoods(index)">
                删除
              </a-button>
            </div>
          </div>
        </template>
      </section>
      <!-- 购物车底部开始 -->
      <div class="footer">
        <a-button
          v-permission="'appLeaseIndexWholeordercancellation'"
          type="primary"
          size="large"
          shape="round"
          ghost
          class="flex-grow"
          :disabled="!totalNum"
          @click="cancelAll"
          >整单取消</a-button
        >
        <a-button
          v-permission="'appLeaseIndexConfirmlease'"
          class="flex-grow ml-3x flex-between align-center"
          type="primary"
          shape="round"
          size="large"
          @click="checkOut"
          :disabled="!totalNum"
        >
          <span class="mr-2x"
            >确定租赁<span class="f-key font-n" v-if="shortcutKey"
              >(Enter)</span
            >
          </span>
          <span class="font-n">共{{ totalNum || 0 }}项</span>
        </a-button>
      </div>
    </div>
    <!-- 购物车结束 -->

    <!-- 输入租赁凭证弹窗 -->
    <a-modal
      title="请输入租赁凭证"
      :visible="voucherVisible"
      @ok="voucherConfirm"
      @cancel="voucherVisible = false"
    >
      <div class="main">
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="{ span: 3 }"
          :wrapper-col="{ span: 21 }"
        >
          <a-form-model-item label="会员号" prop="memberNo">
            <a-input
              v-model="form.memberNo"
              placeholder="扫码查询/输入会员手机号查询"
              v-inputfocus
            >
              <a-icon type="scan" slot="suffix" class="primary font-xl" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="票号" prop="ticketNo">
            <IcCardinput
              v-model="form.ticketNo"
              placeholder="扫码查询/输入票号查询"
              v-inputfocus
            ></IcCardinput>
            <!-- <a-input
              v-model="form.ticketNo"
              placeholder="扫码查询/输入票号查询"
              v-inputfocus
            >
              <a-icon type="scan" slot="suffix" class="primary font-xl" />
            </a-input> -->
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remark">
            <a-input
              type="textarea"
              rows="4"
              v-model="form.remark"
              v-inputfocus
            />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </section>
</template>

<script>
import { productFrontItemsrental } from "@/api/search";
import { mapGetters } from "vuex";
import MemberDetail from "../home/member/MemberDetail.vue";
import { onkeydown } from "@/utils/global";
import { checkText, checkEnOrNumber } from "../../utils/global";
import IcCardinput from "@/components/hardware/IcCardinput";
import MemberMenu from "../../components/member/MemberMenu.vue";

export default {
  components: { MemberDetail, IcCardinput, MemberMenu },
  name: "lease-list",
  data() {
    return {
      searchName: "", //物品名称
      goodsList: [], //物品列表
      cartList: [], //购物车列表
      emptyCar: require("../../assets/images/empty_car.png"),
      voucherVisible: false, //租赁凭证弹窗
      form: {
        memberNo: "",
        ticketNo: "",
        remark: ""
      },
      rules: {
        memberNo: [
          { message: "请输入会员号", trigger: "blur" },
          { validator: checkEnOrNumber, trigger: "blur" }
        ],
        ticketNo: [
          { message: "请输入票号", trigger: "blur" },
          { validator: checkEnOrNumber, trigger: "blur" }
        ],
        remark: [
          { message: "请输入备注", trigger: "blur" },
          { validator: checkText, trigger: "blur" }
        ]
      },
      showMember: false
    };
  },
  created() {
    this.loadData();
  },
  mounted() {
    //开启键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", false);
    window.addEventListener("keydown", this.windowKeyDown, true);
  },
  computed: {
    ...mapGetters({
      shortcutKey: "setting/getshortcutKey"
    }),
    ...mapGetters("common", ["memberInfo"]),
    //总数量
    totalNum() {
      let totalNum = 0;
      for (let i in this.cartList) {
        totalNum += this.cartList[i].buyNum;
      }
      return totalNum;
    }
  },
  methods: {
    windowKeyDown(e) {
      if (!this.shortcutKey) return;
      onkeydown(e, this.underCodeOperating);
    },
    //根据键盘输入值，进行相应的操作
    underCodeOperating(code) {
      switch (code) {
        case 112:
          // console.log("按了F1");
          // this.$router.push({ path: "/retail/MemberDetail" });
          this.showMember = true;
          break;

        case 113:
          // console.log("按了F2");
          this.$router.push({ path: "/lease/deposit/collection" });
          break;

        case 114:
          // console.log("按了F3");
          this.$router.push("/lease/deposit/return");
          break;

        case 115:
          // console.log("按了F4");
          this.$router.push("/lease/goods/return");
          break;

        case 116:
          //  console.log("按了F5");
          this.$router.push("/lease/deposit/record");
          break;

        case 117:
          this.$router.push("/lease/record");
          break;
        default:
          break;
      }
    },
    //获取列表
    loadData() {
      productFrontItemsrental({
        name: this.searchName
      }).then(res => {
        this.goodsList = res;
      });
    },
    //查询
    onSearch(value) {
      this.searchName = value;
      this.loadData();
    },
    //判断当前数量是否大于库存
    isAddNumDisabled(product) {
      return +product.buyNum >= product.stock;
    },
    //点击加购物车
    addGoods(item) {
      //查找购物车是否存在此商品，不存在则添加
      let product = this.cartList.find(u => u.id === item.id);
      if (product === undefined) {
        this.cartList.push({
          id: item.id,
          spuId: item.spuId,
          name: item.name,
          buyNum: 1,
          stock: item.stock,
          deductionType: item.deductionType,
          sellingPrice: item.sellingPrice,
          leassRule: item.leassRule
        });
      }
      //如果购物车已存在此商品，再次点击时，增加数量,超出库存，不允许再增加数量
      if (product) {
        if (product.buyNum >= product.stock) {
          product.buyNum = product.stock;
          this.$message.warning(`当前物品最多能租${product.stock}个`);
          return;
        }
        product.buyNum++;
      }
    },
    //删除单个购物车
    delGoods(index) {
      this.cartList.splice(index, 1);
    },
    //数量减一
    reduceNum(index, product) {
      if (product.buyNum > 1) {
        product.buyNum -= 1;
      } else {
        this.cartList.splice(index, 1);
      }
    },
    //数量加1
    addNum(product) {
      if (!this.isAddNumDisabled(product)) {
        product.buyNum += 1;
      }
    },
    //手动改变数量
    handleChange(product) {
      //处理输入为0的情况
      if (product.buyNum === "0") {
        this.$message.warning(`当前物品最少能租1个`);
        product.buyNum = 1;
        return;
      }
      if (product.buyNum > product.stock) {
        this.$message.warning(`当前物品最多能租${product.stock}个`);
        product.buyNum = product.stock;
        return;
      }
      this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    },
    //整单取消
    cancelAll() {
      this.cartList = [];
    },
    //结账
    checkOut() {
      //打开输入凭证弹窗
      this.voucherVisible = true;
      if (this.memberInfo.id) {
        this.form.memberNo = this.memberInfo.mobile;
      }
    },
    //租赁凭证弹窗确认
    voucherConfirm() {
      if (!this.form.memberNo && !this.form.ticketNo) {
        this.$message.warning("请输入会员手机号或票号");
        return;
      }
      //跳转到确认租赁页面
      sessionStorage.setItem("cartList", JSON.stringify(this.cartList));
      this.$router.push({
        path: "/lease/confirm",
        query: {
          memberNo: this.form.memberNo,
          ticketNo: this.form.ticketNo,
          remark: this.form.remark
        }
      });
    }
  },
  beforeDestroy() {
    //关闭键盘监听
    this.$store.commit("system/setSYSTEM_INPUT_IS_DEFAULT_DOWN", true);
    window.removeEventListener("keydown", this.windowKeyDown, true);
  }
};
</script>

<style lang="less" scoped>
@import "../../assets/less/custom.less";

// 去掉了number的默认样式
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*左边*/
.wrapper-left {
  margin-right: 0;
  /*租赁物品卡片*/
  .ticket-card {
    display: flex;
    height: 160px;
    box-sizing: border-box;
    padding: 16px;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    color: #666;
    background: @primary-light1;
    &:hover,
    &:active {
      box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.2);
    }
    .image {
      width: 100px;
      height: 100px;
      border-radius: 4px;
      flex-shrink: 0; // 防止图片被压缩
    }
  }

  /*底部*/
  .footer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 12px;
    .menu {
      position: relative;
      width: 13%;
      padding: 8px 0;
      border: 1px solid @primary;
      text-align: center;
      color: @primary;
      border-radius: 20px;
      cursor: pointer;
      margin: 0 12px;
      .iconfont {
        font-size: 22px;
        position: absolute;
        top: -15px;
        right: -13px;
      }
      &.action {
        color: #ffffff;
        background-color: @primary;
      }
      &.disabled {
        border-color: @disabled;
        color: @disabled;
        cursor: not-allowed;
        background: #f5f5f5;
      }
    }
  }
}

.shopping-cart {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  width: 364px;
  .header {
    padding: 12px 16px;
    font-size: 24px;
  }
  .main {
    padding: 0 16px 8px;
    font-size: 16px;
    /deep/.ant-btn-link {
      padding: 0 0 0 16px;
      font-size: 18px;
      line-height: 1;
    }

    .container {
      border-top: 3px solid #eff0f3;
      &:first-child {
        border: none;
      }
    }
  }
}
@media screen and (max-width: 1366px) {
  .f-key {
    display: none;
  }
}
</style>
