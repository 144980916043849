<!--
  @name: 确认租赁页
  @author: heyan
  @date:2021-08-19
-->

<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="确认租赁"></panel-head>
    <div class="main mt-2x">
      <a-table :columns="columns" :data-source="data" :pagination="false">
        <div slot="rules" slot-scope="row">
          <span v-if="row.deductionType == 1"
            >按次收费：{{ row.sellingPrice | money }} / 次</span
          >
          <span v-if="row.deductionType == 2"
            >按时收费: {{ row.leassRule | money }}</span
          >
        </div>
      </a-table>
      <!-- 查询出来的押金数据 -->
      <div>
        <!-- 已收取押金 -->
        <div class="lease-box" v-if="merchantLeaseInfo.length > 0">
          <p class="lease-title">已收取押金</p>
          <div
            class="lease-box bd-t"
            v-for="(item, index) in merchantLeaseInfo"
            :key="item.id"
            :index="index"
          >
            <div>
              <span class="lease-text mt-2x">商户名称</span>
              <span>{{ item.merchantName }}</span>
            </div>
            <div>
              <span class="lease-text">会员</span>
              <span v-if="item.memberInfo"
                >{{ item.memberInfo.name }} / {{ item.memberInfo.phone }}</span
              >
              <a-button type="link" @click="check"
                >查看其它商户收取的押金</a-button
              >
            </div>
            <div>
              <span class="lease-text">票号</span>
              <span>{{ item.ticketNo }}</span>
            </div>
            <div>
              <span class="lease-text">门票名称</span>
              <span>{{ item.ticketName }}</span>
            </div>
            <div>
              <span class="lease-text">押金金额</span>
              <span>{{ item.depositAmount | money }}</span>
            </div>
            <div>
              <span class="lease-text">押金余额</span>
              <span>{{ item.surplusDeposit | money }}</span>
            </div>
          </div>
        </div>
        <!-- 其它商户的押金 -->
        <div v-if="otherLeaseInfo.length > 0">
          <div
            class="lease-box bd-t"
            v-show="isShow"
            v-for="(item, index) in otherLeaseInfo"
            :key="item.id"
            :index="index"
          >
            <div>
              <span class="lease-text mt-2x">商户名称</span>
              <span>{{ item.merchantName }}</span>
            </div>
            <div>
              <span class="lease-text">会员</span>
              <span
                >{{ item.memberInfo.name }} / {{ item.memberInfo.phone }}</span
              >
            </div>
            <div>
              <span class="lease-text">票号</span>
              <span>{{ item.ticketNo }}</span>
            </div>
            <div>
              <span class="lease-text">门票名称</span>
              <span>{{ item.ticketName }}</span>
            </div>
            <div>
              <span class="lease-text">押金金额</span>
              <span>{{ item.depositAmount | money }}</span>
            </div>
            <div>
              <span class="lease-text">押金余额</span>
              <span>{{ item.surplusDeposit | money }}</span>
            </div>
          </div>
        </div>
        <!-- 无数据时 -->
        <div
          class="lease-box bd-t pt-2x"
          v-if="otherLeaseInfo.length <= 0"
          v-show="isShow"
        >
          暂无其它商户收取的押金数据
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        type="primary"
        class="btn-lg"
        shape="round"
        size="large"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
      <a-button
        class="ml-3x btn-lg"
        type="primary"
        shape="round"
        size="large"
        @click="confirm"
      >
        确定租赁
      </a-button>
    </footer>
  </div>
</template>

<script>
import PanelHead from "@/components/common/PanelHead.vue";
import {
  getLeaseDeposit,
  determineProductLeaseInfo,
  orderDetailProductcategory
} from "@/api/order";
import { memberInfoOne } from "@/api/member";
import { moneyFormat } from "@/utils/global";

const columns = [
  {
    title: "租赁物品",
    dataIndex: "name"
  },
  {
    title: "租赁数量",
    dataIndex: "buyNum"
  },
  {
    title: "收费规则",
    key: "rules",
    scopedSlots: { customRender: "rules" }
  }
];

export default {
  name: "lease-confirm",
  components: { PanelHead },
  data() {
    return {
      columns,
      data: [], //租赁物品列表
      isShow: false, //是否展示其它商户的押金
      merchantLeaseInfo: [], //查询到当前商户的押金信息
      otherLeaseInfo: [], //其它商户押金信息
      //查询不到押金信息时，自定义提交的数据
      leaseInfo: {
        memberInfo: {
          name: "", //会员名称
          phone: "", //会员手机号
          id: "" //会员ID
        },
        ticketNo: "", //票号
        ticketName: "" //门票名称
      }
    };
  },
  created() {
    this.data = JSON.parse(sessionStorage.getItem("cartList"));
    //查看已收取的押金
    getLeaseDeposit({
      memberNo: this.$route.query.memberNo,
      ticketNo: this.$route.query.ticketNo,
      remark: this.$route.query.remark
    })
      .then(res => {
        //查询到押金信息时
        if (res.length > 0) {
          this.merchantLeaseInfo = res;
        } else {
          //查询不到押金信息时：1、如果有票号，则通过票号查询门票名称  2：如果有会员号，则通过会员手机号查询会员id和名称
          if (this.$route.query.ticketNo) {
            //通过票号查询票号和门票名称信息
            orderDetailProductcategory({
              auxiliaryId: this.$route.query.ticketNo
            }).then(res => {
              if (!res) {
                this.$message.warning("请输入正确的票号，重新查询");
                this.$router.go(-1);
                return;
              }
              this.leaseInfo.ticketName = res.productTypeName;
              this.leaseInfo.ticketNo = res.auxiliaryId;
            });
          }
          //通过会员号查询会员信息
          if (this.$route.query.memberNo) {
            memberInfoOne({
              mobile: this.$route.query.memberNo
            }).then(res => {
              this.leaseInfo.memberInfo.id = res.id;
              this.leaseInfo.memberInfo.name = res.name;
              this.leaseInfo.memberInfo.phone = res.mobile;
            });
          }
        }
      })
      .catch(() => {
        this.$router.go(-1);
      });
  },
  methods: {
    //查看其它商户收取的押金
    check() {
      this.isShow = !this.isShow;
      getLeaseDeposit({
        memberNo: this.$route.query.memberNo,
        ticketNo: this.$route.query.ticketNo,
        remark: this.$route.query.remark,
        type: 1
      })
        .then(res => {
          this.otherLeaseInfo = res;
        })
        .catch(() => {});
    },
    //确定租赁
    confirm() {
      //处理租赁物品表格里的数据，用于提交接口时使用
      let leaseProductInfoDTOList = [];
      let dataList = JSON.parse(JSON.stringify(this.data));
      for (let i = 0; i < dataList.length; i++) {
        let leaseChargeRule;
        if (dataList[i].deductionType === 1) {
          dataList[i].deductionType = "按次收费：";
          leaseChargeRule =
            dataList[i].deductionType +
            moneyFormat(dataList[i].sellingPrice) +
            "/次";
        } else {
          dataList[i].deductionType = "按时收费：";
          leaseChargeRule = dataList[i].deductionType + dataList[i].leassRule;
        }

        leaseProductInfoDTOList.push({
          productId: dataList[i].id,
          productName: dataList[i].name,
          leaseChargeRule: leaseChargeRule,
          leasesNumber: dataList[i].buyNum
        });
      }

      let submitData = {};
      //查询到押金信息时
      if (this.merchantLeaseInfo.length > 0) {
        if (this.merchantLeaseInfo[0].memberInfo) {
          //会员信息存在时，传的参数如下
          submitData = {
            memberId: this.merchantLeaseInfo[0].memberInfo.id,
            memberName: this.merchantLeaseInfo[0].memberInfo.name,
            memberPhone: this.merchantLeaseInfo[0].memberInfo.phone,
            voucherCode: this.merchantLeaseInfo[0].ticketNo,
            productTicketName: this.merchantLeaseInfo[0].ticketName,
            remarks: this.$route.query.remark,
            leaseProductInfoDTOList: leaseProductInfoDTOList
          };
        } else {
          //会员信息不存在时，传的参数如下
          submitData = {
            memberId: "",
            memberName: "",
            memberPhone: "",
            voucherCode: this.merchantLeaseInfo[0].ticketNo,
            productTicketName: this.merchantLeaseInfo[0].ticketName,
            remarks: this.$route.query.remark,
            leaseProductInfoDTOList: leaseProductInfoDTOList
          };
        }
      } else {
        //押金信息查询不到时，传的参数值，是从另外两个接口（查会员，查票号）的返回值取的
        submitData = {
          memberId: this.leaseInfo.memberInfo.id,
          memberName: this.leaseInfo.memberInfo.name,
          memberPhone: this.leaseInfo.memberInfo.phone,
          voucherCode: this.leaseInfo.ticketNo,
          productTicketName: this.leaseInfo.ticketName,
          remarks: this.$route.query.remark,
          leaseProductInfoDTOList: leaseProductInfoDTOList
        };
      }
      determineProductLeaseInfo(submitData)
        .then(() => {
          this.$message.success("下单成功");
          this.$router.go(-1);
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped>
.main {
  padding: 16px;
  .lease-box {
    margin-top: 20px;
    .lease-title {
      padding: 12px 16px;
      font-size: 16px;
      background-color: #f4f9fc;
    }

    .lease-text {
      margin-right: 10px;
      width: 80px;
      display: inline-block;
      line-height: 40px;
    }
  }
}
</style>
