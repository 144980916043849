<template>
  <div>
    <!-- 表格 -->
    <a-table
      class="flex-grow"
      :columns="columns"
      :data-source="tableData"
      :pagination="false"
      :scroll="{ x: 1366, y: 600 }"
      row-key="id"
    >
      <template slot="createTime" slot-scope="{ createTime }">
        {{ createTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template
        slot="purchaserMemberName"
        slot-scope="{ purchaserMemberName, purchaserMemberPhone }"
      >
        {{ purchaserMemberName }}/{{ purchaserMemberPhone }}
      </template>
      <template slot="actualAmount" slot-scope="{ actualAmount }">
        {{ actualAmount | money }}
      </template>
      <template slot="remainLeaseAmount" slot-scope="{ remainLeaseAmount }">
        {{ remainLeaseAmount | money }}
      </template>
      <template slot="leaseReturnTime" slot-scope="{ leaseReturnTime }">
        {{ leaseReturnTime | date("YYYY-MM-DD hh:mm:ss") }}
      </template>
      <template
        slot="orderStatusText"
        slot-scope="{ orderStatusText, orderStatus }"
      >
        <span :class="{ red: orderStatus === 3 }">{{ orderStatusText }}</span>
      </template>
      <template slot="action" slot-scope="row">
        <a-button
          type="link p-0"
          @click="refund(row)"
          :disabled="row.orderStatus !== 3 && row.orderStatus !== 9"
        >
          退押金
        </a-button>
        <a-button class="ml-2x" type="link" @click="toDetail(row)">
          详情
        </a-button>
      </template>
    </a-table>

    <!--退押金弹窗-->
    <a-modal
      title="退还押金"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="visible = false"
      :maskClosable="false"
    >
      <a-form-model
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
      >
        <a-form-model-item label="会员姓名">
          <p>{{ refundInfo.purchaserMemberName }}</p>
        </a-form-model-item>
        <a-form-model-item label="会员手机号">
          <p>{{ refundInfo.purchaserMemberPhone }}</p>
        </a-form-model-item>
        <a-form-model-item label="应退押金">
          <p>{{ refundInfo.totalRefundableAmount | money }}</p>
        </a-form-model-item>
        <a-form-model-item label="退款方式">
          <p class="red">{{ refundInfo.payWayText }}</p>
        </a-form-model-item>
        <a-form-model-item label="实退押金" prop="money">
          <a-input
            class="input"
            v-model="ruleForm.money"
            placeholder="请输入金额"
            allow-clear
            :maxLength="10"
          >
            <p slot="addonAfter">元</p>
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remarks">
          <a-input
            class="input"
            v-model="ruleForm.remarks"
            type="textarea"
            placeholder="请输入备注"
            allow-clear
            :maxLength="200"
          >
            <p slot="addonAfter">元</p>
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import { refund, refundCheck } from "../../../../api/order";
import { checkMoney, checkText } from "../../../../utils/global";

export default {
  name: "DepositList",
  props: {
    tableData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visible: false,
      loading: false,
      ruleForm: {
        money: "", // 编辑退款金额
        remarks: "" // 备注
      },
      rules: {
        remark: [
          {
            validator: checkText,
            trigger: "blur"
          }
        ],
        money: [
          { required: true, message: "请输入实退押金金额", trigger: "blur" },
          { validator: checkMoney, trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value > this.refundInfo.totalRefundableAmount / 100) {
                callback(new Error("实退押金不能大于应退押金"));
              } else {
                callback();
              }
            },
            trigger: "blur"
          }
        ]
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      refundInfo: {
        refundableProductList: []
      }, // 退款信息
      columns: [
        {
          title: "商户名称",
          dataIndex: "orderSaleMerchantName",
          width: 130
        },
        {
          title: "押金收取时间",
          scopedSlots: { customRender: "createTime" },
          width: 130
        },
        {
          title: "会员",
          scopedSlots: { customRender: "purchaserMemberName" },
          width: 150
        },
        {
          title: "票号",
          dataIndex: "auxiliaryId",
          width: 120
        },
        {
          title: "收取押金金额",
          scopedSlots: { customRender: "actualAmount" },
          width: 120
        },
        {
          title: "剩余押金金额",
          scopedSlots: { customRender: "remainLeaseAmount" },
          width: 120
        },
        {
          title: "押金收取备注",
          dataIndex: "remarks",
          width: 180
        },
        {
          title: "押金状态",
          scopedSlots: { customRender: "orderStatusText" },
          width: 100
        },
        {
          title: "押金退还时间",
          scopedSlots: { customRender: "leaseReturnTime" },
          width: 130
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          width: 130
        }
      ]
    };
  },
  methods: {
    // 退押金
    refund(row) {
      refundCheck({ orderId: row.id })
        .then(res => {
          this.visible = true;
          this.refundInfo = res;
          this.ruleForm.money = res.totalRefundableAmount / 100;
        })
        .catch(() => {});
    },
    // 确定
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          const data = {
            orderId: this.refundInfo.orderId,
            remarks: this.ruleForm.remarks
          };
          let money = Math.round(this.ruleForm.money * 10000) / 100;
          // 计算手续费
          let commission = this.refundInfo.totalRefundableAmount - money;
          // 如果输入的金额小于应退金额，则存在退款手续费信息
          if (commission > 0) {
            data.refundableDTO = {
              totalCommissionAmount: commission, // 退款手续费
              totalRefundAmount: money, // 应退款总金额
              totalRefundPayAmount: money, // 实退现金金额
              totalMemberBalanceRefundAmount: 0, // 实退会员金额
              totalIntegralDeductionRefundAmount: 0 // 实退积分金额
            };
            data.refundableDTO.refundableProductList = [
              {
                id: this.refundInfo.refundableProductList[0].id,
                totalCommissionAmount: commission
              }
            ];
          }
          this.loading = true;
          refund(data)
            .then(() => {
              this.$message.success("提交成功");
              this.visible = false;
              this.$emit("reload");
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },
    // 去详情
    toDetail(row) {
      this.$router.push({
        path: "/lease/deposit/change/list",
        query: {
          id: row.id
        }
      });
    }
  }
};
</script>

<style scoped></style>
