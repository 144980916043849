<!--
  @name: 押金收取记录
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <div class="wrapper flex-col flex-between ">
    <panel-head title="押金收取记录"></panel-head>

    <section class="main mt-2x">
      <header class="container">
        <!--头部查询-->
        <a-form-model layout="inline" :model="ruleForm">
          <a-form-model-item label="押金收取时间">
            <a-range-picker
              :allow-clear="false"
              class="input"
              v-model="dateRange"
              @change="dateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="押金状态">
            <a-select
              class="input"
              v-model="ruleForm.status"
              placeholder="请选择订单状态"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option :value="1">未退还</a-select-option>
              <a-select-option :value="2">已退还</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="票号">
            <a-input
              class="input"
              v-model="ruleForm.auxiliaryId"
              placeholder="请扫码查询/输入票码"
              @pressEnter="search"
              allow-clear
            >
              <a-icon type="scan" slot="suffix" class="font-l primary" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item>
            <a-button type="primary" @click="search">查询</a-button>
            <a-button class="mh-2x" @click="reset">重置</a-button>
            <a-button type="link" @click="visibleSearch = true"
              >高级查询</a-button
            >
          </a-form-model-item>
        </a-form-model>

        <!--高级查询-->
        <a-drawer
          title="高级查询"
          placement="right"
          :width="400"
          :closable="false"
          :visible="visibleSearch"
          @close="visibleSearch = false"
        >
          <a-form-model
            ref="heightForm"
            :model="ruleForm"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label-align="left"
          >
            <a-form-model-item label="押金收取时间">
              <a-range-picker
                :allow-clear="false"
                class="input"
                v-model="dateRange"
                @change="dateChange"
              />
            </a-form-model-item>
            <a-form-model-item label="票号">
              <a-input
                class="input"
                v-model="ruleForm.auxiliaryId"
                placeholder="请扫码查询/输入票码"
                allow-clear
              >
                <a-icon type="scan" slot="suffix" class="font-l primary" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="会员手机号">
              <a-input
                class="input"
                v-model="ruleForm.memberPhone"
                placeholder="请输入会员手机号"
                allow-clear
              >
                <a-icon type="scan" slot="suffix" class="font-l primary" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="押金状态">
              <a-select
                class="input"
                v-model="ruleForm.status"
                placeholder="请选择订单状态"
                allow-clear
              >
                <a-select-option value="">全部</a-select-option>
                <a-select-option :value="1">未退还</a-select-option>
                <a-select-option :value="2">已退还</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="押金收取人">
              <a-input
                class="input"
                v-model="ruleForm.salespersonName"
                placeholder="请输入押金收取人"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item label="押金收取备注">
              <a-input
                class="input"
                v-model="ruleForm.remarks"
                placeholder="请输入押金收取备注"
                allow-clear
              >
              </a-input>
            </a-form-model-item>
            <!--          <a-form-model-item label="押金退还时间">
              <a-range-picker
                :allow-clear="false"
                class="input"
                v-model="dateRange"
                @change="dateChange"
              />
            </a-form-model-item>
            <a-form-model-item label="押金退还人">
              <a-input
                class="input"
                v-model="ruleForm.user"
                placeholder="请输入押金退还人"
              >
              </a-input>
            </a-form-model-item>-->
            <a-form-model-item label=" " :colon="false">
              <a-button type="primary" class="mr-2x" @click="search"
                >查询</a-button
              >
              <a-button @click="reset">重置</a-button>
            </a-form-model-item>
          </a-form-model>
        </a-drawer>
      </header>
      <!--表格-->
      <deposit-list
        :table-data="data"
        class="flex-grow"
        @reload="loadData"
      ></deposit-list>
      <!--底部-->
      <footer class="flex-end p-2x">
        <a-pagination
          v-model="ruleForm.page"
          :default-page-size="ruleForm.size"
          :total="total"
          :page-size-options="['10', '20', '50', '100']"
          show-size-changer
          @change="loadData"
          @showSizeChange="sizeChange"
        />
        <!--<a-button type="primary" ghost @click="$router.back()">返回</a-button>-->
      </footer>
    </section>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import moment from "moment";
import { orderDeposittakerecord } from "../../../api/search";
import DepositList from "./component/DepositList";
export default {
  name: "deposit-record",
  components: { PanelHead, DepositList },
  data() {
    return {
      moment,
      dateRange: [moment(), moment()],
      ruleForm: {
        page: 1,
        size: 10,
        chargeBeginTime: moment()
          .startOf("date")
          .valueOf(), // 开始时间
        chargeEndTime: moment()
          .endOf("date")
          .valueOf(), // 结束时间
        auxiliaryId: "", // 票号
        memberPhone: "", // 会员手机号
        status: "",
        salespersonName: "", // 收取押金人
        remarks: ""
      },
      visibleSearch: false,
      // 查询表单
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      data: [],
      total: 0
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      orderDeposittakerecord(this.ruleForm)
        .then(({ records, total }) => {
          this.data = records;
          this.total = total;
        })
        .catch(() => {});
    },
    // 日期选择
    dateChange([start, end]) {
      this.ruleForm.chargeBeginTime = moment(start)
        .startOf("date")
        .valueOf();
      this.ruleForm.chargeEndTime = moment(end)
        .endOf("date")
        .valueOf();
    },
    // 条数改变
    sizeChange(current, size) {
      this.ruleForm.page = 1;
      this.ruleForm.size = size;
      this.loadData();
    },
    // 重置表单
    reset() {
      this.dateRange = [moment(), moment()];
      this.ruleForm.chargeBeginTime = "";
      this.ruleForm.chargeEndTime = "";
      this.ruleForm.auxiliaryId = "";
      this.ruleForm.memberPhone = "";
      this.ruleForm.status = "";
      this.ruleForm.salespersonName = "";
      this.ruleForm.remarks = "";
    },
    // 搜索
    search() {
      this.page = 1;
      this.loadData();
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 230px;
  margin-right: 16px;
}
</style>
