<!--
  @name: 物品归还页面
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="物品归还"></panel-head>
    <div class="main mt-2x">
      <!-- 表单：查询 -->
      <a-form-model
        class="p-2x"
        layout="inline"
        ref="searchForm"
        :model="searchForm"
        :rules="searchFormRules"
      >
        <a-form-model-item label="会员号码" prop="memberPhone">
          <a-input
            class="input"
            allow-clear
            placeholder="请扫码查询/输入会员手机号查询"
            v-model="searchForm.memberPhone"
          >
            <a-icon class="font-l primary" type="scan" slot="suffix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="票号" prop="voucherCode">
          <a-input
            class="input"
            allow-clear
            placeholder="请输入票号"
            v-model="searchForm.voucherCode"
          >
            <a-icon class="font-l primary" type="scan" slot="suffix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remarks">
          <a-input
            class="input"
            allow-clear
            placeholder="请输入备注"
            v-model="searchForm.remarks"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="reload">查询</a-button>
        </a-form-model-item>
        <a-form-model-item>
          <a-button @click="reset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 表格 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :row-selection="rowSelection"
        :scroll="{ x: 1366, y: 600 }"
        :pagination="false"
      >
        <template slot="memberName" slot-scope="{ memberName, memberPhone }">
          {{ memberName }}/{{ memberPhone }}
        </template>
        <template slot="leaseBeginTime" slot-scope="{ leaseBeginTime }">
          {{ leaseBeginTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template
          slot="leaseShouldPayAmount"
          slot-scope="{ leaseShouldPayAmount }"
        >
          <span class="red">{{ leaseShouldPayAmount | money }}</span>
        </template>
        <template slot="action" slot-scope="row">
          <a-button type="link p-0" @click="show('one', row)">
            归还结算
          </a-button>
        </template>
      </a-table>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <span class="font-xl mr-3x">
        结算费用：<span class="price">
          {{ (productReturnForm.customPrice * 100) | money }}
        </span>
      </span>
      <div class="flex-start align-center">
        <span class="mr-2x">
          <a-icon class="mr-1x" type="history" />结算剩余时间：{{ timerText }}
        </span>
        <a-button
          class="btn-lg"
          type="primary"
          size="large"
          shape="round"
          ghost
          @click="$router.back()"
          >返回</a-button
        >
        <a-button
          class="ml-2x btn-lg"
          type="primary"
          size="large"
          shape="round"
          @click="show('more', '')"
        >
          归还结算
        </a-button>
      </div>
    </footer>
    <!-- 分钟计时器 -->
    <minute-timer
      :isSame="false"
      @get-timer="getTimer"
      @end-timer="endTimer"
    ></minute-timer>
    <!-- 模态框 -->
    <a-modal
      width="600px"
      :body-style="{
        minHeight: '400px',
        maxHeight: '550px',
        overflowY: 'auto'
      }"
      title="物品归还"
      v-model="dialogVisible"
      @cancel="resetForm"
    >
      <!-- 表单：物品归还 -->
      <a-form-model
        ref="productReturnForm"
        :model="productReturnForm"
        :rules="productReturnFormRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        label-align="left"
      >
        <a-form-model-item label="商户名称">
          {{ productDetail.merchantName }}
        </a-form-model-item>
        <a-form-model-item label="门票名称">
          {{ productDetail.ticketName }}
        </a-form-model-item>
        <a-form-model-item label="票号">
          {{ productDetail.voucherCode }}
        </a-form-model-item>
        <a-form-model-item label="会员">
          {{ productDetail.memberName }}/{{ productDetail.memberPhone }}
        </a-form-model-item>
        <a-form-model-item label="商户已收押金" prop="whetherDepositFirst">
          {{ productDetail.remainDepositAmount | money }}
          <a-checkbox
            class="ml-4x"
            v-model="productReturnForm.whetherDepositFirst"
            @change="calcCustomPrice"
          >
            优先扣除押金
          </a-checkbox>
        </a-form-model-item>
        <a-form-model-item label="应收租赁费用" prop="customPrice">
          <a-input
            allow-clear
            v-model="productReturnForm.customPrice"
            addon-after="元"
          />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input
            type="textarea"
            rows="4"
            allow-clear
            v-model="productReturnForm.remark"
          />
        </a-form-model-item>
      </a-form-model>
      <div slot="footer" class="flex-between align-center">
        <span class="mr-2x">
          <a-icon class="mr-1x" type="history" />结算剩余时间：{{ timerText }}
        </span>
        <div>
          <a-button class="mr-2x" @click="resetForm">
            取消
          </a-button>
          <a-button
            type="primary"
            :loading="loading"
            @click="submitForm('productReturnForm')"
          >
            确定
          </a-button>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import MinuteTimer from "../../../components/common/MinuteTimer.vue";
import { checkMoney, checkText } from "../../../utils/global";
import { orderItemsreturned } from "../../../api/search";
import { returnProduct } from "../../../api/cart";
import { payOfflineOrder } from "../../../api/order";

// 表格表头
const columns = [
  {
    title: "商户名称",
    dataIndex: "merchantName",
    width: 200,
    fixed: "left"
  },
  {
    title: "租赁会员",
    scopedSlots: { customRender: "memberName" },
    width: 200
  },
  {
    title: "票号",
    dataIndex: "voucherCode",
    width: 150
  },
  {
    title: "租赁物品名称",
    dataIndex: "productName",
    width: 150
  },
  {
    title: "收费规则",
    dataIndex: "leaseChargeRule",
    width: 200
  },
  {
    title: "租用操作人",
    dataIndex: "hireOperator",
    width: 150
  },
  {
    title: "租用时间",
    scopedSlots: { customRender: "leaseBeginTime" },
    width: 200
  },
  {
    title: "租赁时长(分钟)",
    dataIndex: "leaseDuration",
    width: 100
  },
  {
    title: "备注",
    dataIndex: "remarks",
    width: 200
  },
  {
    title: "应收费用",
    scopedSlots: { customRender: "leaseShouldPayAmount" },
    width: 150
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];

export default {
  name: "GoodsReturn",
  components: { PanelHead, MinuteTimer },
  data() {
    return {
      // 表单：查询
      searchForm: {
        memberPhone: "", // 票号/会员号码
        voucherCode: "", // 票号
        remarks: "" // 备注
      },
      // 表单验证：查询
      searchFormRules: {},
      data: [], // 表格数据源
      columns, // 表格表头
      page: 1, // 当前页码
      size: 10, // 每页显示条数
      total: 0, // 总条数
      checkOne: [], // 已选列表id
      checkList: [], // 已选列表ids
      productDetail: {}, // 物品详情
      dialogVisible: false, // 是否显示模态框
      returnType: "", // 物品归还类型
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      // 表单：物品归还
      productReturnForm: {
        whetherDepositFirst: true, // 是否优先扣除押金
        customPrice: 0, // 应收租赁费用
        remark: "" // 备注
      },
      // 表单验证：物品归还
      productReturnFormRules: {
        customPrice: [
          { required: true, message: "请输入押金", trigger: "blur" },
          { validator: checkMoney, trigger: "blur" }
        ],
        remark: [{ validator: checkText, trigger: "blur" }]
      },
      tempPrice: 0, // 临时的应收租赁费用
      loading: false, // 按钮是否显示加载中
      timerText: "" // 倒计时文本内容
    };
  },
  computed: {
    // 已选列表ids
    rowSelection() {
      return {
        onChange: (...row) => {
          this.productReturnForm.customPrice = 0; // 初始化应收租赁费用
          this.checkList = []; // 初始化已选列表ids
          row[1].forEach(item => {
            // 应收租赁费用
            this.productReturnForm.customPrice +=
              item.leaseShouldPayAmount / 100;
            this.checkList.push(item.id);
          });
        }
      };
    }
  },
  methods: {
    // 查询物品归还列表
    loadData() {
      let { memberPhone, voucherCode } = this.searchForm;
      if (memberPhone === "" && voucherCode === "") {
        this.$message.error("会员号码和票号至少输入一个");
        return false;
      }
      orderItemsreturned(this.searchForm)
        .then(res => {
          this.data = res;
          this.data.length > 0 ? (this.productDetail = this.data[0]) : {}; // 物品详情
        })
        .catch(() => {});
    },

    // 重新加载数据
    reload() {
      this.page = 1;
      this.loadData();
    },

    // 重置
    reset() {
      this.searchForm.remarks = ""; // 初始化备注
      this.reload();
    },

    // 计算应收租赁费用
    calcCustomPrice() {
      if (this.productReturnForm.whetherDepositFirst === true) {
        if (
          this.productDetail.remainDepositAmount / 100 -
            this.productReturnForm.customPrice >=
          0
        ) {
          this.productReturnForm.customPrice = 0;
        } else {
          this.productReturnForm.customPrice =
            this.productReturnForm.customPrice -
            this.productDetail.remainDepositAmount / 100;
        }
      } else {
        this.productReturnForm.customPrice =
          Math.round(this.tempPrice * 100) / 100; // 处理小数
      }
    },

    // 显示模态框
    show(type, row) {
      this.returnType = type;
      if (type === "one") {
        this.productReturnForm.customPrice = row.leaseShouldPayAmount / 100; // 应收租赁费用
        this.tempPrice = row.leaseShouldPayAmount / 100; // 临时的应收租赁费用
        this.calcCustomPrice(); // 计算应收租赁费用
        this.checkOne = [row.id];
        this.dialogVisible = true;
      } else if (type === "more") {
        let checkList = this.checkList;
        this.productReturnForm.customPrice = 0; // 初始化应收租赁费用
        this.data.forEach(item => {
          if (checkList.includes(item.id)) {
            this.productReturnForm.customPrice +=
              item.leaseShouldPayAmount / 100; // 应收租赁费用
            this.tempPrice += item.leaseShouldPayAmount / 100; // 临时的应收租赁费用
            this.calcCustomPrice(); // 计算应收租赁费用
          }
        });
        if (checkList.length <= 0) {
          this.$message.error("请选择归还的物品");
          return false;
        }
        this.dialogVisible = true;
      }
    },

    // 提交表单
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true;
          // 不需要再收押金
          let params = {
            productList:
              this.returnType === "one" ? this.checkOne : this.checkList, // 已选列表ids
            ...this.productReturnForm
          };
          params.customPrice *= 100; // 应收租赁费用乘以100
          // 需要再收现金
          if (
            this.productReturnForm.whetherDepositFirst === false ||
            this.productDetail.remainDepositAmount / 100 -
              this.productReturnForm.customPrice <
              0
          ) {
            this.$store.commit("order/setEndOfPayConfig", {
              backUrl: this.$route.path,
              logoutMember: true
            }); // 支付完成后跳转到物品归还页面
            this.$router.push({
              path: "/lease/goods/order/info",
              query: {
                ids: encodeURIComponent(
                  JSON.stringify(
                    this.returnType === "one" ? this.checkOne : this.checkList
                  )
                ), // 已选列表ids
                queryParams: encodeURIComponent(
                  JSON.stringify(this.searchForm)
                ), // 查询参数
                returnParams: encodeURIComponent(JSON.stringify(params)) // 归还物品参数
              }
            });
            this.loading = false;
            return false;
          }
          returnProduct(params)
            .then(res => {
              payOfflineOrder({
                orderId: res.orderId, // 订单号
                payWay: 9 // 免支付
              })
                .then(() => {
                  this.dialogVisible = false;
                  this.$message.success("物品归还成功");
                  this.reload();
                })
                .catch(() => {})
                .finally(() => {
                  this.loading = false;
                });
            })
            .catch(() => {});
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    },

    // 关闭模态框
    resetForm() {
      this.productReturnForm.whetherDepositFirst = true; // 初始化是否优先扣除押金
      this.productReturnForm.customPrice = 0; // 初始化应收租赁费用
      this.tempPrice = 0; // 初始化应收租赁费用
      this.productReturnForm.remark = ""; // 初始化备注
      this.dialogVisible = false;
    },

    // 获取时间文本
    getTimer(timerText) {
      this.timerText = timerText;
    },

    // 结束计时
    endTimer() {
      this.$router.back();
    }
  }
};
</script>

<style lang="less" scoped>
@import "../../../assets/less/custom.less";

.label {
  padding: 12px 8px !important;
}

.input {
  width: 260px;
}

.footer {
  justify-content: space-between;
}

/deep/.ant-input-group {
  .ant-input {
    color: @red;
  }
}
</style>
