<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="确认订单"></panel-head>
    <div class="main mt-2x">
      <!-- 表格 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :scroll="{ x: 1366, y: 600 }"
        :pagination="false"
      >
        <template
          slot="leaseShouldPayAmount"
          slot-scope="{ leaseShouldPayAmount }"
        >
          <span class="red">{{ leaseShouldPayAmount | money }}</span>
        </template>
        <template slot="action" slot-scope="row">
          <a-button type="link p-0" @click="show('one', row)">
            归还结算
          </a-button>
        </template>
      </a-table>
      <div class="mt-2x p-2x text-r bg-primary">
        <p>
          共<span class="mh-x red">{{ data.length || 0 }}</span
          >项，应收合计：{{ totalReceivable | money }}
        </p>
        <p class="mt-2x">押金抵扣金额：{{ deductionAmount | money }}</p>
        <p class="mt-2x">
          应付总额：<span class="red">{{ totalPayable | money }}</span>
        </p>
      </div>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        class="btn-lg"
        type="primary"
        size="large"
        shape="round"
        ghost
        @click="$router.back()"
      >
        返回
      </a-button>
      <a-button
        class="ml-2x btn-lg"
        type="primary"
        size="large"
        shape="round"
        @click="createOrder"
      >
        订单支付
      </a-button>
    </footer>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import { orderItemsreturned } from "../../../api/search";
import { returnProduct } from "../../../api/cart";
// import { payOfflineOrder } from "../../../api/order";

// 表格表头
const columns = [
  {
    title: "租赁物品",
    dataIndex: "productName",
    width: 150
  },
  {
    title: "单价",
    scopedSlots: { customRender: "leaseShouldPayAmount" },
    width: 150
  },
  {
    title: "数量",
    dataIndex: "num",
    width: 100
  },
  {
    title: "小计",
    scopedSlots: { customRender: "leaseShouldPayAmount" },
    width: 150
  }
];

export default {
  name: "OrderInfo",
  components: { PanelHead },
  data() {
    return {
      ids: JSON.parse(decodeURIComponent(this.$route.query.ids)), // 已选列表ids
      queryParams: JSON.parse(
        decodeURIComponent(this.$route.query.queryParams)
      ), // 查询参数
      returnParams: JSON.parse(
        decodeURIComponent(this.$route.query.returnParams)
      ), // 归还物品参数
      data: [], // 表格数据源
      columns, // 表格表头
      totalReceivable: 0, // 应收合计
      totalPayable: 0, // 应付总额
      deductionAmount: 0 // 押金抵扣金额
    };
  },
  created() {
    this.loadData(); // 查询物品归还列表
  },
  methods: {
    // 查询物品归还列表
    loadData() {
      orderItemsreturned(this.queryParams)
        .then(res => {
          // 已选列表ids
          if (this.ids.length) {
            res.forEach(item => {
              for (let temp of this.ids) {
                if (temp === item.id) {
                  this.data.push({
                    productName: item.productName, // 物品名称
                    num: 1, // 数量
                    leaseShouldPayAmount: item.leaseShouldPayAmount // 单价/小计
                  });
                  break;
                }
              }
            });
            this.data.forEach(item => {
              this.totalReceivable += item.leaseShouldPayAmount; // 应收合计
            });
            // 是否优先扣除押金
            if (this.returnParams.whetherDepositFirst === false) {
              this.deductionAmount = 0; // 应付总额
              this.totalPayable = this.totalReceivable; // 应付总额
            } else {
              this.deductionAmount = res[0].remainDepositAmount; // 押金抵扣金额
              this.totalReceivable - res[0].remainDepositAmount; // 应付总额
            }
          } else {
            this.data = [];
          }
        })
        .catch(() => {});
    },

    // 订单支付
    createOrder() {
      returnProduct(this.returnParams)
        .then(res => {
          this.$message.success("下单成功");
          this.$router.push({
            path: "/newPay",
            query: {
              orderNumber: res.orderId, // 订单id
              totalFee: res.settlementPrice // 总价
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="less" scoped></style>
