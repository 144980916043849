<!--
  @name: 租赁记录页面
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="租赁记录"></panel-head>
    <div class="main mt-2x">
      <!-- 表单 -->
      <a-form-model
        class="p-2x"
        ref="searchForm"
        layout="inline"
        :model="searchForm"
        :rules="searchFormRules"
      >
        <a-form-model-item label="租赁日期">
          <a-range-picker
            class="input"
            v-model="leaseDate"
            @change="leaseDateChange"
          />
        </a-form-model-item>
        <a-form-model-item label="租赁状态">
          <a-select
            class="input"
            v-model="searchForm.status"
            placeholder="请选择租赁状态"
          >
            <a-select-option value="">
              全部
            </a-select-option>
            <a-select-option :value="1">
              未归还
            </a-select-option>
            <a-select-option :value="2">
              已归还
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="票号">
          <a-input
            class="input"
            allow-clear
            placeholder="请输入票号"
            v-model="searchForm.voucherCode"
          >
            <a-icon type="scan" slot="suffix" class="font-l primary" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="reload">查询</a-button>
          <a-button class="mh-2x" @click="reset">重置</a-button>
          <a-button type="link" @click="searchVisible = true">
            高级查询
          </a-button>
        </a-form-model-item>
      </a-form-model>
      <!-- 高级查询 -->
      <a-drawer
        title="高级查询"
        placement="right"
        :width="420"
        :closable="false"
        :visible="searchVisible"
        @close="searchVisible = false"
      >
        <a-form-model
          ref="searchForm"
          :model="searchForm"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          label-align="left"
        >
          <a-form-model-item label="租赁日期">
            <a-range-picker
              class="input"
              v-model="leaseDate"
              @change="leaseDateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="票号">
            <a-input
              class="input"
              allow-clear
              v-model="searchForm.voucherCode"
              placeholder="请输入票号"
            >
              <a-icon class="font-l primary" type="scan" slot="suffix" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="会员手机号">
            <a-input
              class="input"
              allow-clear
              v-model="searchForm.memberPhone"
              placeholder="请扫码查询/输入会员手机号查询"
            >
              <a-icon class="font-l primary" type="scan" slot="suffix" />
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="租赁物品名称">
            <a-input
              class="input"
              allow-clear
              v-model="searchForm.productName"
              placeholder="请输入租赁物品名称"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="租赁状态">
            <a-select
              class="input"
              v-model="searchForm.status"
              placeholder="请选择租赁状态"
            >
              <a-select-option value="">全部</a-select-option>
              <a-select-option :value="1">未归还</a-select-option>
              <a-select-option :value="2">已归还</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="租赁操作人">
            <a-input
              class="input"
              allow-clear
              v-model="searchForm.salespersonName"
              placeholder="请输入租赁操作人"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="归还时间">
            <a-range-picker
              class="input"
              v-model="returnDate"
              @change="returnDateChange"
            />
          </a-form-model-item>
          <a-form-model-item label="归还操作人">
            <a-input
              class="input"
              allow-clear
              v-model="searchForm.returnOperator"
              placeholder="请输入归还操作人"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item label=" " :colon="false">
            <a-button type="primary" class="mr-2x" @click="reload">
              查询
            </a-button>
            <a-button @click="reset">重置</a-button>
          </a-form-model-item>
        </a-form-model>
      </a-drawer>
      <!-- 表格 -->
      <a-table
        class="flex-grow"
        :columns="columns"
        :data-source="data"
        :row-key="
          (record, index) => {
            return index;
          }
        "
        :scroll="{ x: 1366, y: 600 }"
        :pagination="false"
      >
        <template slot="memberName" slot-scope="{ memberName, memberPhone }">
          {{ memberName }}/{{ memberPhone }}
        </template>
        <template slot="leaseBeginTime" slot-scope="{ leaseBeginTime }">
          {{ leaseBeginTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <!-- 租赁状态：1未归还 2已归还 -->
        <template slot="status" slot-scope="{ status }">
          <span class="disabled" v-if="status === 1">未归还</span>
          <span class="primary" v-else>已归还</span>
        </template>
        <template
          slot="leaseShouldPayAmount"
          slot-scope="{ leaseShouldPayAmount }"
        >
          <span class="red">{{ leaseShouldPayAmount | money }}</span>
        </template>
        <template slot="leaseEndTime" slot-scope="{ leaseEndTime }">
          {{ leaseEndTime | date("YYYY-MM-DD hh:mm:ss") }}
        </template>
        <template
          slot="leaseActualPayAmount"
          slot-scope="{ leaseActualPayAmount }"
        >
          <span class="red">{{ leaseActualPayAmount | money }}</span>
        </template>
        <template slot="action" slot-scope="row">
          <a-button type="link p-0" @click="show(row)">
            租赁详情
          </a-button>
        </template>
      </a-table>
      <!-- 分页 -->
      <footer class="flex-end p-2x">
        <a-pagination
          v-model="page"
          :default-page-size="size"
          :total="total"
          :show-total="total => `共 ${total} 条`"
          show-size-changer
          :page-size-options="['10', '20', '50', '100']"
          @change="pageChange"
          @showSizeChange="sizeChange"
        />
      </footer>
    </div>
    <!-- 模态框 -->
    <a-modal
      title="租赁详情"
      v-model="dialogVisible"
      :width="600"
      :body-style="{
        minHeight: '365px',
        maxHeight: '500px',
        overflowY: 'auto'
      }"
    >
      <div class="title-mid">租赁信息</div>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          商户名称：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.merchantName }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          会员：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.memberName }}/{{ leaseDetail.memberPhone }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          票号：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.voucherCode }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          门票名称：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.ticketName }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          租赁物品名称：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.productName }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          收费规则：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseChargeRule }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          租赁状态：
        </a-col>
        <a-col :span="12">
          <span class="disabled" v-if="leaseDetail.status === 1">未归还</span>
          <span class="primary" v-else>已归还</span>
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          租用时间：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseBeginTime | date("YYYY-MM-DD hh:mm:ss") }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          租用操作人：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.hireOperator }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          租用时长：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseDuration }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          应收租赁费用：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseShouldPayAmount | money }}
        </a-col>
      </a-row>
      <div class="title-mid">归还信息</div>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          实收费用：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseActualPayAmount | money }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          归还备注：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.remarks }}
        </a-col>
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          归还时间：
        </a-col>
        <a-col :span="12">
          {{ leaseDetail.leaseEndTime | date("YYYY-MM-DD hh:mm:ss") }}</a-col
        >
      </a-row>
      <a-row :gutter="[16, 16]">
        <a-col :span="6">
          归还操作人：
        </a-col>
        <a-col :span="12">{{ leaseDetail.returnOperator }} </a-col>
      </a-row>
      <template slot="footer">
        <a-button class="mr-2x" @click="close">
          取消
        </a-button>
        <a-button type="primary" @click="close">
          确定
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import moment from "moment";
import { orderLeaserecord } from "@/api/search";

// 表格表头
const columns = [
  {
    title: "商户名称",
    dataIndex: "merchantName",
    width: 200,
    fixed: "left"
  },
  {
    title: "租赁物品名称",
    dataIndex: "productName",
    width: 200
  },
  {
    title: "收费规则",
    dataIndex: "leaseChargeRule",
    width: 200
  },
  {
    title: "会员",
    scopedSlots: { customRender: "memberName" },
    width: 200
  },
  {
    title: "票号",
    dataIndex: "voucherCode",
    width: 150
  },
  {
    title: "租赁状态",
    scopedSlots: { customRender: "status" },
    width: 100
  },
  {
    title: "租用时间",
    scopedSlots: { customRender: "leaseBeginTime" },
    width: 200
  },
  {
    title: "租用操作人",
    dataIndex: "hireOperator",
    width: 150
  },
  {
    title: "租赁时长(分钟)",
    dataIndex: "leaseDuration",
    width: 100
  },
  {
    title: "应收费用",
    scopedSlots: { customRender: "leaseShouldPayAmount" },
    width: 150
  },
  {
    title: "归还时间",
    scopedSlots: { customRender: "leaseEndTime" },
    width: 200
  },
  {
    title: "实收费用",
    scopedSlots: { customRender: "leaseActualPayAmount" },
    width: 150
  },
  {
    title: "收费方式",
    dataIndex: "payWayText",
    width: 100
  },
  {
    title: "归还操作人",
    dataIndex: "returnOperator",
    width: 150
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: 100,
    fixed: "right"
  }
];

export default {
  name: "LeaseRecord",
  components: { PanelHead },
  data() {
    return {
      moment,
      labelCol: { span: 7 },
      wrapperCol: { span: 17 },
      leaseDate: [moment(), moment()], // 租赁日期
      returnDate: ["", ""], // 归还日期
      // 查询表单
      searchForm: {
        leaseBeginDate: moment()
          .startOf("date")
          .valueOf(), // 租用开始日期
        leaseEndDate: moment()
          .endOf("date")
          .valueOf(), // 租用结束日期
        voucherCode: "", // 票号
        memberPhone: "", // 会员手机号
        productName: "", // 租赁物品名称
        status: "", // 租赁状态
        hireOperator: "", // 租赁操作人
        returnBeginTime: "", // 归还开始日期
        returnEndTime: "", // 归还结束日期
        returnOperator: "" // 归还操作人
      },
      searchFormRules: {}, // 查询表单验证规则
      searchVisible: false, // 是否显示高级查询表单
      data: [], // 表格数据源
      columns, // 表格表头
      page: 1, // 当前页码
      size: 10, // 每页显示条数
      total: 0, // 总条数
      leaseDetail: {}, // 租赁详情
      dialogVisible: false // 是否显示模态框
    };
  },
  created() {
    this.loadData(); // 查询租赁列表
  },
  methods: {
    // 查询物品租赁列表
    loadData() {
      let params = {
        page: this.page, // 当前页码
        size: this.size, // 每页显示条数
        ...this.searchForm
      };
      orderLeaserecord(params)
        .then(({ records, total }) => {
          // 字段没有值时返回“-”
          // let arr = [];
          // records.forEach(item => {
          //   for (let key of Object.keys(item)) {
          //     if (item[key] === null) {
          //       item[key] = "-";
          //     }
          //   }
          //   arr.push(item);
          // });
          // this.data = arr;
          this.data = records;
          this.total = total;
        })
        .catch(() => {});
    },

    // 重新加载数据
    reload() {
      this.page = 1;
      this.loadData();
    },

    // 重置
    reset() {
      this.$refs.searchForm.resetFields();
      this.reload();
    },

    // 页码改变
    pageChange(...params) {
      this.page = params[0];
      this.loadData();
    },

    // 条数改变
    sizeChange(...params) {
      this.size = params[1];
      this.reload();
    },

    // 选择租赁日期
    leaseDateChange([start, end]) {
      if (start && end) {
        this.searchForm.leaseBeginDate = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.leaseEndDate = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.leaseBeginDate = "";
        this.searchForm.leaseEndDate = "";
      }
    },

    // 选择归还日期
    returnDateChange([start, end]) {
      if (start && end) {
        this.searchForm.returnBeginTime = moment(start)
          .startOf("date")
          .valueOf();
        this.searchForm.returnEndTime = moment(end)
          .endOf("date")
          .valueOf();
      } else {
        this.searchForm.returnBeginTime = "";
        this.searchForm.returnEndTime = "";
      }
    },

    // 显示模态框
    show(row) {
      this.leaseDetail = row;
      this.dialogVisible = true;
    },

    // 关闭模态框
    close() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.input {
  width: 260px;
}
</style>
