<!--
  @author: 邓良友
  @name: 分钟计时器
  @description: 默认从 5 分钟开始倒计时，需要自定义值传 mm
  @props: {
    // 是否用同一个时间
    isSame: {
      type: Boolean,
      default: true
    },
    // 分钟
    mm: {
      type: Number,
      default: 5
    }
-->
<template>
  <div>
    <span v-if="isSame">{{ timerText }}</span>
  </div>
</template>

<script>
export default {
  name: "MinuteTimer",
  props: {
    // 是否用同一个时间
    isSame: {
      type: Boolean,
      default: true
    },
    // 分钟
    mm: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      timer: null, // 定时器
      timerText: "" // 倒计时文本内容
    };
  },
  created() {
    this.setTimer(); // 设置定时器
  },
  beforeDestroy() {
    this.clearTimer(); // 清除定时器
  },
  methods: {
    // 设置定时器
    setTimer() {
      let tempMM = this.mm, // 分钟
        tempSS = 0; // 秒数
      this.timer = setInterval(() => {
        if (!tempMM && !tempSS) {
          this.$emit("end-timer"); // 结束计时
          this.clearTimer();
          return false;
        }
        if (tempSS === 0) {
          tempMM--;
          tempSS = 60;
        }
        tempSS--;
        this.timerText = `${tempMM > 9 ? tempMM : "0" + tempMM}:${
          tempSS > 9 ? tempSS : "0" + tempSS
        }`;
        this.$emit("get-timer", this.timerText); // 获取倒计时文本内容
      }, 1000);
    },

    // 清除定时器
    clearTimer() {
      clearInterval(this.timer);
      this.timer = null;
    }
  }
};
</script>

<style lang="less" scoped></style>
