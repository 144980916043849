<!--
  @name: 收取押金页面
  @author: fengyanlong
  @date:2021-08-19
-->

<template>
  <div class="flex-col flex-between wrapper">
    <panel-head title="收取押金"></panel-head>
    <div class="main mt-2x">
      <!-- 表单：押金收取 -->
      <a-form-model
        class="pv-4x"
        ref="depositForm"
        :model="depositForm"
        :rules="depositFormRules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item label="商户名称">
          {{ leaseMerchantName }}
        </a-form-model-item>
        <a-form-model-item label="会员号" prop="memberCode">
          <a-input
            allow-clear
            placeholder="请扫码查询/输入会员手机号查询"
            v-model="depositForm.memberCode"
          >
            <a-icon class="font-l primary" type="scan" slot="suffix" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="票号" prop="ticketNo">
          <!-- <a-input allow-clear placeholder="请输入票号" v-model="depositForm.ticketNo">
            <a-icon class="font-l primary" type="scan" slot="suffix" />
          </a-input> -->
          <IcCardinput
            v-model="depositForm.ticketNo"
            placeholder="扫码查询/输入票号查询"
          ></IcCardinput>
        </a-form-model-item>
        <a-form-model-item label="押金" prop="customPrice">
          <a-input
            allow-clear
            placeholder="请输入押金"
            v-model="depositForm.customPrice"
            addon-after="元"
          />
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <a-input
            type="textarea"
            rows="6"
            allow-clear
            placeholder="请输入备注"
            v-model="depositForm.remark"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <!-- 底部 -->
    <footer class="footer">
      <a-button
        class="mr-2x btn-lg"
        type="primary"
        shape="round"
        size="large"
        ghost
        @click="$router.back()"
        >返回</a-button
      >
      <a-button
        class="btn-lg"
        type="primary"
        shape="round"
        size="large"
        @click="submitForm('depositForm')"
      >
        收取押金
      </a-button>
    </footer>
  </div>
</template>

<script>
import PanelHead from "../../../components/common/PanelHead.vue";
import { mapGetters } from "vuex";
import { checkMoney, checkText, checkEnOrNumber } from "../../../utils/global";
import { skuLeaseDeposit } from "../../../api/product";
import { createCart } from "../../../api/cart";
import IcCardinput from "../../../components/hardware/IcCardinput.vue";

export default {
  name: "DepositCollection",
  components: { PanelHead, IcCardinput },
  data() {
    return {
      leaseMerchantName: localStorage.getItem("leaseMerchantName") || "-", // 租赁商户名称
      productDetail: {}, // 产品详情
      labelCol: { span: 8 },
      wrapperCol: { span: 8 },
      // 表单：押金收取
      depositForm: {
        memberCode: "", // 会员号
        ticketNo: "", // 票号
        customPrice: "", // 押金
        remark: "" // 备注
      },
      // 表单验证：押金收取
      depositFormRules: {
        memberCode: [{ validator: checkEnOrNumber, trigger: "blur" }],
        ticketNo: [{ validator: checkEnOrNumber, trigger: "blur" }],
        customPrice: [
          { required: true, message: "请输入押金", trigger: "blur" },
          { validator: checkMoney, trigger: "blur" }
        ],
        remark: [{ validator: checkText, trigger: "blur" }]
      }
    };
  },
  created() {
    this.depositForm.memberCode = this.memberInfo.mobile || ""; // 会员号
    this.loadData(); // 查询租赁产品详情
  },
  computed: {
    ...mapGetters("common", ["memberInfo"])
  },
  methods: {
    // 查询租赁产品详情
    loadData() {
      skuLeaseDeposit()
        .then(res => {
          this.productDetail = res;
        })
        .catch(() => {});
    },

    // 提交表单
    submitForm(formName) {
      let { memberCode, ticketNo } = this.depositForm;
      if (memberCode === "" && ticketNo === "") {
        this.$message.error("会员号和票号至少输入一个");
        return false;
      }

      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            businessCategory: 1, // 业务类型（1.正常下单，2.自定义收款，3.加购，4.充值，5.会员特权，6.取票，7.补办）
            // 购物车产品列表
            cartProductList: [
              {
                productId: this.productDetail.id, // 产品skuId
                spuId: this.productDetail.spuId, // 产品spuId
                buyNum: 1 // 购买数量
              }
            ],
            ...this.depositForm
          };
          params.customPrice *= 100; // 押金乘以100
          createCart(params)
            .then(res => {
              this.$message.success("下单成功!");
              this.$store.commit("order/setEndOfPayConfig", {
                backUrl: this.$route.path,
                logoutMember: true
              }); // 支付完成后跳转到租赁首页
              this.$router.push({
                path: "/newPay",
                query: {
                  orderNumber: res.createOrderVO.orderId, // 订单id
                  totalFee: res.totalPrice // 总价
                }
              });
            })
            .catch(() => {});
        } else {
          this.$message.warning("验证不通过，请重新检查");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
